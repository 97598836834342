import React from "react";
import { useState, useEffect } from "react";
import { Routes, Route, Link, NavigateFunction, useNavigate } from "react-router-dom";
import Login from "./components/Login";
import LicenseVerification from "./components/LicenseVerification";
import LicenseGenerator from "./components/LicenseGenerator";
import Settings from "./components/Settings";
import Identity from "./types/identity";
import { getIdentity, logout } from "./services/api.service";
import { anErrorOccurred } from "./services/errors";
import { getErrorMessage } from "./services/utils";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const App: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const [identity, setIdentity] = useState<Identity | null>(null);
  const [activeTab, setActiveTab] = useState<string>('');

  useEffect(() => {
    setActiveTab(window.location.pathname);
    loadIdentity();
  }, []);

  const loadIdentity = async () => {
    try {
      const response = await getIdentity();
      if (response.status === 200) {
        setIdentity(response.data);
      } else {
        navigate('/login');
      }
    } catch (e) {
      navigate('/login');
    }
  };

  const logOut = async () => {
    try {
      const response = await logout();
      if (response.status === 200) {
        setIdentity(null);
        navigate('/login');
      } else {
        alert(anErrorOccurred);
      }
    } catch (e) {
      alert(getErrorMessage(e));
    }
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <Link to={"/"} className="nav-link logo">
            SensMax Software Licensing Manager
          </Link>
          <div className="d-flex justify-content-start">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {identity && <li className="nav-item">
                <Link to={"/verification"} className={activeTab === '/verification' || activeTab === '/' ? 'nav-link active' : 'nav-link'} onClick={(e) => setActiveTab('/verification')}>
                  License Verification
                </Link>
              </li>}
              {identity?.isAdmin && <li className="nav-item">
                <Link to={"/generator"} className={activeTab === '/generator' ? 'nav-link active' : 'nav-link'} onClick={(e) => setActiveTab('/generator')}>
                  License Generator
                </Link>
              </li>}
              {identity?.isAdmin && <li className="nav-item">
                <Link to={"/settings"} className={activeTab === '/settings' ? 'nav-link active' : 'nav-link'} onClick={(e) => setActiveTab('/settings')}>
                  Settings
                </Link>
              </li>}
              {!identity && <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>}
              {identity && <li className="nav-item">
                <button className="nav-link" onClick={logOut}>
                  Logout
                </button>
              </li>}
            </ul>
          </div>
        </div>
      </nav>

      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<LicenseVerification identity={identity} />} />
          <Route path="/verification" element={<LicenseVerification identity={identity} />} />
          <Route path="/generator" element={<LicenseGenerator identity={identity} />} />
          <Route path="/settings" element={<Settings identity={identity} />} />
          <Route path="/login" element={<Login setIdentity={setIdentity} setActiveTab={setActiveTab} />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
