import React, { useState } from "react";
import { deactivateLicense, getLicenseInfo, removeLicense } from "../services/api.service";
import License from "../types/licence";
import { getErrorMessage, toDateFormat } from "../services/utils";
import { licenseKeyNotFound } from "../services/errors";
import Identity from "../types/identity";

export interface LicenseVerificationProps {
  identity?: Identity | null
};

const LicenseVerification: React.FC<LicenseVerificationProps> = (props: LicenseVerificationProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [licenseKey, setLicenseKey] = useState<string>('');
  const [licenseInfo, setlicenseInfo] = useState<string>('');
  const [license, setLicense] = useState<License | null>(null);
  const [error, setError] = useState<string>('');

  const onSearchChange = (e: any) => {
    const value = e.target.value;
    setLicenseKey(value);
  }

  const searchLicense = async () => {
    if (!licenseKey) {
      setError(licenseKeyNotFound);
      setlicenseInfo('');
      setLicense(null);
      return;
    }
    setLoading(true);
    try {
      const response = await getLicenseInfo(licenseKey, true);
      if (response.data) {
        setError('');
        const licenseView = toLicenseView(response.data);
        setlicenseInfo(licenseView);
        setLicense(response.data);
      } else {
        setlicenseInfo('');
        setLicense(null);
        setError(licenseKeyNotFound);
      }
    } catch (e) {
      setError(getErrorMessage(e));
    }
    setLoading(false);
  }

  const toLicenseView = (license: License) => {
    let firstActivation = '';
    if (license.history.length) {
      const activationDate = new Date(license.history[license.history.length - 1].createdOnUtc);
      firstActivation = toDateFormat(activationDate);
    }
    const createdDate = toDateFormat(new Date(license.createdOnUtc));
    return `Created Date: ${createdDate}\nSoftware: ${license.software}\nStatus: ${license.status}\nFirst Activation: ${firstActivation}\nActivation Count: ${license.activationCount}\nHardware ID: ${license.hardwareIdCryptoHash ?? ''}`
  }

  const deactivate = async () => {
    if (!license) {
      setError(licenseKeyNotFound);
      return;
    }
    setLoading(true);
    try {
      const response = await deactivateLicense(license.licenseKey, license.hardwareIdCryptoHash ?? '');
      if (response.status === 200) {
        setError('');
        await searchLicense();
      } else {
        setError(licenseKeyNotFound);
      }
    } catch (e) {
      setError(getErrorMessage(e));
    }
    setLoading(false);
  }

  const remove = async () => {
    if (!license) {
      setError(licenseKeyNotFound);
      return;
    }
    setLoading(true);
    try {
      const response = await removeLicense(license.licenseKey);
      if (response.status === 200) {
        setError('');
        await searchLicense();
      } else {
        setError(licenseKeyNotFound);
      }
    } catch (e) {
      setError(getErrorMessage(e));
    }
    setLoading(false);
  }

  return (
    <div className="container ">
      <div className="row justify-content-md-center">
        <div className="col-6">
          <div className="row justify-content-between align-items-end">
            <div className="col">
              <label className="form-label">License key</label>
              <input id="search-input" type="text" className="form-control" placeholder="" aria-label="License key" onChange={onSearchChange} />
            </div>
            <div className="col-3 d-grid">
              <button type="submit" className="btn btn-primary" onClick={searchLicense} disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                )}
                <span>Search</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-6">
          <label className="form-label">License info</label>
          <textarea id="search-result" className="form-control" placeholder="" aria-label="License info" defaultValue={licenseInfo} />
        </div>
      </div>
      <br />
      <div className="row justify-content-md-center">
        <div className="col-6">
          <div className="row justify-content-between align-items-start">
            <div className="col-3 d-grid">
              <button type="submit" className="btn btn-primary" onClick={deactivate} disabled={loading || !license || license.status !== 'Activated'}><span>Deactivate</span></button>
            </div>
            {props.identity?.isAdmin && <div className="col-3 d-grid">
              <button type="submit" className="btn btn-primary" onClick={remove} disabled={loading || !license || license.status === 'Removed'}><span>Remove</span></button>
            </div>}
          </div>
        </div>
      </div>
      <br />
      <div className="row justify-content-md-center">
        {error && <div className="col-6">
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        </div>}
      </div>
    </div>
  );
};

export default LicenseVerification;
