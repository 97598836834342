import React, { Dispatch, SetStateAction, useState } from "react";
import { anErrorOccurred } from "../services/errors";
import { getIdentity, login } from "../services/api.service";
import { getErrorMessage } from "../services/utils";
import Identity from "../types/identity";
import { NavigateFunction, useNavigate } from "react-router-dom";

export interface LicenseVerificationProps {
  setIdentity: Dispatch<SetStateAction<Identity | null>>,
  setActiveTab: Dispatch<SetStateAction<string>>
};

const Login: React.FC<LicenseVerificationProps> = (props: LicenseVerificationProps) => {
  const navigate: NavigateFunction = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const doLogin = async () => {
    setLoading(true);
    try {
      const loginResponse = await login(username, password);
      if (loginResponse.status !== 200) {
        setError(anErrorOccurred);
      }
      const identityResponse = await getIdentity();
      if (loginResponse.status !== 200) {
        setError(anErrorOccurred);
      }
      props.setIdentity(identityResponse.data);
      props.setActiveTab('/verification');
      setError('');
      navigate('/verification');
    } catch (e) {
      setError(getErrorMessage(e));
    }
    setLoading(false);
  };

  return (
    <div className="col-md-12">
      <div className="row justify-content-md-center">
        {error && <div className="col-6">
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        </div>}
      </div>
      <div className="card card-container">
        <div className="row g-3">
          <div className="form-group">
            <input placeholder="Username" name="username" type="text" className="form-control" onChange={(e) => setUserName(e.target.value)} />
          </div>
          <div className="form-group">
            <input placeholder="Password" name="password" type="password" className="form-control" onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div className="col-12 d-grid gap-2">
            <button type="submit" className="btn btn-primary" disabled={loading || !username || !password} onClick={doLogin}>
              {loading && (
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
              )}
              <span>Login</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
