export const toDateFormat = (date: Date) => {
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const dateString = `${dd}/${mm}/${yyyy} ${hours}:${minutes}`;
  return dateString;
};

export const getErrorMessage = (e: any) => {
  if (e.response && e.response.data && e.response.data.message) {
    return e.response.data.message;
  } else if (e.message) {
    return e.message;
  } else {
    return e;
  }
}
