import axios, { AxiosResponse } from "axios";
import License from "../types/licence";
import Software from "../types/software";
import LicenseTxtModel from "../types/licenseTxtModel";
import User from "../types/user";
import Identity from "../types/identity";

const getApiUrl = (): string => {
  //const url = "https://localhost:5000"; // DEV url
  const url = window.location.protocol + '//' + window.location.host;
  return url;
}

const axiosInstance = axios.create({
  baseURL: getApiUrl(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: "no-cache",
  },
  withCredentials: true,
});

axiosInstance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response
    && error.response.status === 401
    && !window.location.href.includes('/login')) {
    return window.location.href = '/login'
  }
  return Promise.reject(error);
});

export const getLicenseInfo = (licenseKey: string, includeHistory: boolean): Promise<AxiosResponse<License>> => {
  return axiosInstance.post("/admin/api/license/get", {
    licenseKey,
    includeHistory
  });
};

export const deactivateLicense = (licenseKey: string, hardwareIdCryptoHash: string): Promise<AxiosResponse> => {
  return axiosInstance.post("/public/api/license/deactivate", {
    licenseKey,
    hardwareIdCryptoHash
  });
};

export const removeLicense = (licenseKey: string): Promise<AxiosResponse> => {
  return axiosInstance.post("/admin/api/license/remove", {
    licenseKey
  });
};

export const getSoftware = (): Promise<AxiosResponse<Software[]>> => {
  return axiosInstance.get("/admin/api/software/get");
};

export const generateLicense = (count: number, softwareId: number): Promise<AxiosResponse<LicenseTxtModel[]>> => {
  return axiosInstance.post("/admin/api/license/add", {
    count,
    softwareId
  });
};

export const saveSoftware = (software: Software[]): Promise<AxiosResponse> => {
  return axiosInstance.post("/admin/api/software/save", {
    data: software
  });
};

export const getUsers = (): Promise<AxiosResponse<User[]>> => {
  return axiosInstance.get("/admin/api/account/users");
};

export const saveUsers = (users: User[]): Promise<AxiosResponse> => {
  return axiosInstance.post("/admin/api/account/save", {
    data: users
  });
};

export const login = (username: string, password: string) => {
  return axiosInstance.post("/admin/api/account/login", {
    username,
    password
  });
};

export const logout = (): Promise<AxiosResponse> => {
  return axiosInstance.post("/admin/api/account/logout");
};

export const getIdentity = (): Promise<AxiosResponse<Identity>> => {
  return axiosInstance.get("/admin/api/account/identity");
};