import React, { useState, useEffect } from "react";
import Software from "../types/software";
import { getSoftware, getUsers, saveSoftware, saveUsers } from "../services/api.service";
import { anErrorOccurred, softwareNotFound, usersNotFound } from "../services/errors";
import User from "../types/user";
import { getErrorMessage } from "../services/utils";
import Identity from "../types/identity";
import { NavigateFunction, useNavigate } from "react-router-dom";

export interface SettingsProps {
  identity?: Identity | null
};

const Settings: React.FC<SettingsProps> = (props: SettingsProps) => {
  const navigate: NavigateFunction = useNavigate();
  const [softwareList, setSoftwareList] = useState<Software[]>([]);
  const [userList, setUserList] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [validSoftwareForm, setValidSoftwareForm] = useState<boolean>(false);
  const [validUserForm, setValidUserForm] = useState<boolean>(false);

  useEffect(() => {
    if (props.identity) {
      if (!props.identity.isAdmin) {
        navigate("/login");
      }
      else {
        loadDataRequesrs();
      }
    }
  }, [props.identity]);

  useEffect(() => {
    if (softwareList.length) {
      const validSoftwares = softwareList.every(software => {
        return software.name && software.downloadLink && validateUrl(software.downloadLink);
      });
      setValidSoftwareForm(validSoftwares);
    }
  }, [softwareList]);

  useEffect(() => {
    if (userList.length) {
      const validUsers = userList.every(user => {
        return user.userName && validatePassword(user.password, user.resetPassword, user.id);
      });
      setValidUserForm(validUsers);
    }
  }, [userList]);

  const loadDataRequesrs = async () => {
    setLoading(true);
    await softwareRequest();
    await usersRequest();
    setLoading(false);
  }

  const softwareRequest = async () => {
    try {
      const response = await getSoftware();
      if (response.data && response.data.length) {
        setError('');
        setSoftwareList(response.data);
      } else {
        setSoftwareList([]);
        setError(softwareNotFound);
      }
    } catch (e) {
      setError(getErrorMessage(e));
    }
  };

  const usersRequest = async () => {
    try {
      const response = await getUsers();
      if (response.data && response.data.length) {
        setError('');
        setUserList(response.data);
      } else {
        setSoftwareList([]);
        setError(usersNotFound);
      }
    } catch (e) {
      setError(getErrorMessage(e));
    }
  };

  const addSoftware = () => {
    let minSoftwareId = softwareList.length ? Math.min(...softwareList.map(o => o.id)) : 0;
    if (minSoftwareId > 0) {
      minSoftwareId = 0;
    }
    minSoftwareId--;
    let newSoftwareList = [...softwareList];
    const software: Software = {
      id: minSoftwareId,
      name: '',
      downloadLink: ''
    };
    newSoftwareList.push(software);
    setSoftwareList(newSoftwareList);
  }

  const addUser = () => {
    let minUserId = userList.length ? Math.min(...userList.map(o => o.id)) : 0;
    if (minUserId > 0) {
      minUserId = 0;
    }
    minUserId--;
    let newUserList = [...userList];
    const user: User = {
      id: minUserId,
      userName: '',
      password: '',
      resetPassword: false
    };
    newUserList.push(user);
    setUserList(newUserList);
  }

  const resetPassword = (id: number) => {
    let userIndex = userList.findIndex(u => u.id === id);
    if (userIndex >= 0) {
      let userListToUpdate = [...userList];
      userListToUpdate[userIndex].resetPassword = true;
      setUserList(userListToUpdate);
    }
  }

  const onSoftwareNameChange = (e: any, id: number) => {
    let softwareIndex = softwareList.findIndex(s => s.id === id);
    if (softwareIndex >= 0) {
      let softwareListToUpdate = [...softwareList];
      softwareListToUpdate[softwareIndex].name = e.target.value;
      setSoftwareList(softwareListToUpdate);
    }
  }

  const onDownloadLinkChange = (e: any, id: number) => {
    let softwareIndex = softwareList.findIndex(s => s.id === id);
    if (softwareIndex >= 0) {
      let softwareListToUpdate = [...softwareList];
      softwareListToUpdate[softwareIndex].downloadLink = e.target.value;
      setSoftwareList(softwareListToUpdate);
    }
  }

  const onUserNameChange = (e: any, id: number) => {
    let userIndex = userList.findIndex(s => s.id === id);
    if (userIndex >= 0) {
      let userListToUpdate = [...userList];
      userListToUpdate[userIndex].userName = e.target.value;
      setUserList(userListToUpdate);
    }
  }

  const onPasswordChange = (e: any, id: number) => {
    let userIndex = userList.findIndex(s => s.id === id);
    if (userIndex >= 0) {
      let userListToUpdate = [...userList];
      userListToUpdate[userIndex].password = e.target.value;
      setUserList(userListToUpdate);
    }
  }

  const removeSoftware = (id: number) => {
    const newSoftwareList = softwareList.filter(s => s.id !== id);
    setSoftwareList(newSoftwareList);
  }

  const removeUser = (id: number) => {
    const newUserList = userList.filter(u => u.id !== id);
    setUserList(newUserList);
  }

  const save = async () => {
    setLoading(true);
    try {
      const saveSoftwareResponse = await saveSoftware(softwareList);
      const saveUsersResponse = await saveUsers(userList);
      if (saveSoftwareResponse.status === 200
        && saveUsersResponse.status === 200) {
        setError('');
      } else {
        setError(anErrorOccurred);
      }
      await loadDataRequesrs();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(getErrorMessage(e));
    }
  }

  const validateUrl = (value: string) => {
    try {
      const url = new URL(value);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (_) {
      return false;
    }
  }

  const validatePassword = (value: string, reset: boolean, userId: number) => {
    return (userId > 0 && !reset) || value;
  }

  return (
    <div className="container">
      <div className="row justify-content-md-center">
        {error && <div className="col-6">
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        </div>}
      </div>
      <div className="row">
        <div className="col-4">
          <label className="form-label">Software</label>
        </div>
        <div className="col">
          <label className="form-label">Download Link</label>
        </div>
        <div className="col-2">
        </div>
      </div>
      {softwareList.map(software => (
        <div className="row align-items-start settings-row" key={software.id}>
          <div className="col-4">
            <input type="text" className="form-control" placeholder="" aria-label="Software" maxLength={256} value={software.name} onChange={(e) => onSoftwareNameChange(e, software.id)} />
            <div className={!software.name ? 'invalid-feedback validation-message' : 'invalid-feedback'}>
              Name is empty
            </div>
          </div>
          <div className="col">
            <input type="text" className="form-control" placeholder="" aria-label="Download Link" maxLength={4000} value={software.downloadLink} onChange={(e) => onDownloadLinkChange(e, software.id)} />
            <div className={!validateUrl(software.downloadLink) ? 'invalid-feedback validation-message' : 'invalid-feedback'}>
              URL is invalid
            </div>
          </div>
          <div className="col-2 d-grid">
            <button type="submit" className="btn btn-primary" onClick={(e) => removeSoftware(software.id)}><span>Remove</span></button>
          </div>
        </div>
      ))}
      <div className="row">
        <div className="col-2 d-grid">
          <button type="submit" className="btn btn-primary" onClick={addSoftware}><span>Add software</span></button>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-4">
          <label className="form-label">User</label>
        </div>
        <div className="col">
          <label className="form-label">Password</label>
        </div>
        <div className="col-2">
        </div>
      </div>
      {userList.map(user => (
        <div className="row align-items-start settings-row" key={user.id}>
          <div className="col-4">
            <input type="text" className="form-control" placeholder="" aria-label="Username" value={user.userName} onChange={(e) => onUserNameChange(e, user.id)} />
            <div className={!user.userName ? 'invalid-feedback validation-message' : 'invalid-feedback'}>
              Name is empty
            </div>
          </div>
          <div className="col">
            {user.id <= 0 || user.resetPassword
              ? <input type="text" className="form-control" placeholder="" aria-label="Password" value={user.password} onChange={(e) => onPasswordChange(e, user.id)} />
              : <div className="row">
                <div className="col-4 d-grid">
                  <button type="submit" className="btn btn-primary" onClick={(e) => resetPassword(user.id)}><span>Reset password</span></button>
                </div>
              </div>
            }
            <div className={!validatePassword(user.password, user.resetPassword, user.id) ? 'invalid-feedback validation-message' : 'invalid-feedback'}>
              Password is empty
            </div>
          </div>
          <div className="col-2 d-grid">
            {props.identity?.userId !== user.id &&
              <button type="submit" className="btn btn-primary" onClick={(e) => removeUser(user.id)}><span>Remove</span></button>
            }
          </div>
        </div>
      ))
      }
      <div className="row">
        <div className="col-2 d-grid">
          <button type="submit" className="btn btn-primary" onClick={addUser}><span>Add user</span></button>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-2 d-grid">
          <button type="submit" className="btn btn-primary" onClick={save} disabled={loading || !validSoftwareForm || !validUserForm}>
            {loading && (
              <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
            )}
            <span>Save</span>
          </button>
        </div>
      </div>
    </div >
  );
};

export default Settings;